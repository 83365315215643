<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} Ingressos Por PDV</h3>
        </div>
        <div class="card-body table-responsive">
          <form action="">
            <div class="form-group row">
                <div class="col-md-6">
                  <label class="col-md-12">Eventos:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_eventos"
                    placeholder="Selecione o evento..."
                    v-model="form.eventos_id"
                  />
                </div>
                <div class="col-md-6">
                  <label class="col-md-12">Pdv:*</label>
                  <treeselect
                    :multiple="lista_campos != '' ?false : true"
                    :options="lista_pdvs"
                    placeholder="Selecione o pdv..."
                    v-model="form.pdv_id"
                  />
                </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a descricao..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                    @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
                    class="btn btn-primary"
                    :disabled="verif"
                >
                  Salvar
                  <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { fireAlert } from "@/core/mixins/alertMessageMixin";
import EventoService from "@/core/services/evento.service"

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        eventos_id:  EventoService.getEventoId(),

      pdv_id: null,
      descricao: null,
      },
      verif: false,
    };
  },
  created() {
    
    this.preenxerCampos();
  },
  computed: {
    lista_eventos() {
      return  EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label:  cat.id+ " - " + cat.nome_evento
      }));
    },
    lista_pdvs() {
      return this.$store.state.pdv.lista_pdvs.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.pdvsEvento.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Pdv no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("pdvsEvento/create_pdvs_evento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'pdv'
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("pdvsEvento/update_pdvs_evento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'pdv'
      });
    },
   async preenxerCampos() {
    await this.$store.dispatch("pdv/listar_pdvs")
      await this.$store.dispatch("evento/listar_eventos");
      if (this.lista_campos != '')
        this.form = {
          id: this.lista_campos.id,
          eventos_id: this.lista_campos.eventos_id,
          pdv_id: this.lista_campos.pdv_id,
          descricao: this.lista_campos.descricao,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>