const EVENTO = "ucpEvento";
const EVENTO_ID = "ucpEventoId";

export const getEventos = () => {
    return JSON.parse(window.localStorage.getItem(EVENTO));
};

export const saveEventos = Evento => {
    window.localStorage.setItem(EVENTO, JSON.stringify(Evento));
};

export const destroyEvento = () => {
    window.localStorage.removeItem(EVENTO);
    window.localStorage.removeItem(EVENTO_ID);
};

export const setEventoId = filialId => {
    window.localStorage.setItem(EVENTO_ID, filialId);
}

export const getEventoId = () => {
    return window.localStorage.getItem(EVENTO_ID);
}

export default {getEventos, saveEventos, destroyEvento, setEventoId, getEventoId};